
import { WeddingApi } from '@/api/WeddingApi';
import BotWeddingEventForm from '@/components/bot/wedding/BotWeddingEventForm.vue';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { WeddingEventSettings } from '@/types';
import isEqual from 'fast-deep-equal/es6';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'bot-wedding-event-create-modal',
  components: { BotWeddingEventForm },
})
export default class BotWeddingEventCreateModal extends Vue {
  @Prop({ required: true, type: Boolean, default: false })
  readonly show!: boolean;

  isSubmitting = false;

  eventSettings: WeddingEventSettings = this.resetValue();

  get settingsHaveChanged(): boolean {
    return !isEqual(this.eventSettings, this.resetValue());
  }

  async onSubmit() {
    // const data: CreateWlcmHomeCampaign = {
    //   ...this.campaignSettings,
    // };
    this.isSubmitting = true;
    try {
      await WeddingApi.createBotWeddingEvent(this.$route.params.id, {
        ...this.eventSettings,
        // title: 'test',
        // description: 'test',
      });
      // await this.$store.dispatch('bots/create', data);
      this.$notify.success('Successfully created project');
      this.$emit('update:show', false);
      this.$emit('refresh');
    } catch (e) {
      this.$notify.error({
        title: 'Creating a project failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  resetValue(): WeddingEventSettings {
    return {
      name: '',
      title: '',
      description: '',
      slug: '',
      styles: {
        title: {
          color: '#000000',
        },
        description: {
          color: '#000000',
        },
        background: {
          color: '#FFFFFF',
        }, 
      },
      date: ''
    };
  }

  @Watch('show')
  reset() {
    this.eventSettings = this.resetValue();
  }
}
