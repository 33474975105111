
import { WeddingApi } from '@/api/WeddingApi';
import BotAvatar from '@/components/bot/BotAvatar.vue';
import { AuthMixin } from '@/mixins/AuthMixin';
import { WeddingEvent } from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'bot-wedding-event-list-card',
  components: { BotAvatar },
})
export default class BotWeddingEventListCard extends mixins(AuthMixin) {
  events: WeddingEvent[] = [];

  async mounted() {
      await this.loadEvents();
  }

  async loadEvents() {
    try {
      this.events = await WeddingApi.loadBotWeddingEvents(this.$route.params.id);
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        description: 'Failed to load events',
      });
    }
  }

  // sort by last updated desc
  sortEvents = (a: WeddingEvent, b: WeddingEvent) => {
    const lastUpdatedA = new Date(a.lastUpdatedAt).getTime();
    const lastUpdatedB = new Date(b.lastUpdatedAt).getTime();

    if (lastUpdatedA > lastUpdatedB) {
      return -1;
    } else if (lastUpdatedA < lastUpdatedB) {
      return 1;
    }
    return 0;
  };
  filterEvents = (event: WeddingEvent, filterBy: string) => event.name.toLowerCase().includes(filterBy);
  getEventKey = (event: WeddingEvent) => event.id;
}
