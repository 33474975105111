
import { ProjectViewMixin } from '@/mixins/ProjectViewMixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import BotWeddingEventListCard from '@/components/bot/wedding/BotWeddingEventListCard.vue';
import BotWeddingEventCreateModal from '@/components/bot/wedding/BotWeddingEventCreateModal.vue';

@Component({
  name: 'project-wedding-events',
  components: { BotWeddingEventListCard, BotWeddingEventCreateModal },
})
export default class ProjectWeddingEvents extends mixins(ProjectViewMixin) {
  showModal = false;

  refresh() {
    (this.$refs.eventListCard as BotWeddingEventListCard).loadEvents();
  }
}
